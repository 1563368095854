import React from "react"
import { GatsbySeo, LogoJsonLd } from "gatsby-plugin-next-seo"
import { SiteMetaConfig, LandingMetaConfig } from "../../config/config-data"


const FrontSeo = () => (
  <>
    <GatsbySeo
      title={LandingMetaConfig.seo_title || SiteMetaConfig.site_name}
      description={LandingMetaConfig.seo_description || SiteMetaConfig.site_description}
      canonical={SiteMetaConfig.site_url}
      openGraph={{
        type: "website",
        url: SiteMetaConfig.site_url,
        title: LandingMetaConfig.seo_title || SiteMetaConfig.site_name,
        description: LandingMetaConfig.seo_description || SiteMetaConfig.site_description,
        images: [
          { url: LandingMetaConfig.seo_image || SiteMetaConfig.site_image },
        ],
        site_name: SiteMetaConfig.site_name,
      }}
      twitter={{
        handle: `@${SiteMetaConfig.site_twitter_handle}`,
        site: `@${SiteMetaConfig.site_name}`,
        cardType: "summary_large_image",
      }}
      facebook={{
        appId: SiteMetaConfig.site_facebook_app_id,
      }}
      htmlAttributes={{
        lang: SiteMetaConfig.site_language,
      }}
      noindex={LandingMetaConfig.seo_no_index ? true : false}
      nofollow={LandingMetaConfig.seo_no_follow ? true : false}
    />
    <LogoJsonLd
      logo={`${SiteMetaConfig.site_url}/assets/icon.svg`}
      url={SiteMetaConfig.site_url}
    />
  </>
);

export default FrontSeo